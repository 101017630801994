import {
  MentoringSheetImageWithUploadUrlAndDownloadUrl,
  MentoringSheetImageBase,
  WithTimestamp,
  WithId,
  MentoringSheetImageWithDownloadUrl,
} from 'types';

import { API_URL } from 'config';

export const getMentoringSheetImages = async ({
  client_id,
  mentoring_sheet_id,
  token,
}: {
  client_id: string;
  mentoring_sheet_id: string;
  token: string;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}/images`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    const text = await response.text();
    throw new Error(text || 'エラーが発生しました');
  }
  return response.json() as Promise<
    WithTimestamp<WithId<MentoringSheetImageBase>>[]
  >;
};

export const getMentoringSheetImageById = async ({
  client_id,
  mentoring_sheet_id,
  image_id,
  token,
}: {
  client_id: string;
  mentoring_sheet_id: string;
  image_id: string;
  token: string;
}) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}/images/${image_id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) {
    const text = await response.text();
    throw new Error(text || 'エラーが発生しました');
  }
  return response.json() as Promise<
    WithTimestamp<WithId<MentoringSheetImageWithDownloadUrl>>
  >;
};

export const postMentoringSheetImage = async ({
  client_id,
  mentoring_sheet_id,
  token,
  ...data
}: {
  client_id: string;
  mentoring_sheet_id: string;
  token: string;
} & MentoringSheetImageBase) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}/images`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    const text = await response.text();
    throw new Error(text || 'エラーが発生しました');
  }

  return response.json() as Promise<
    WithTimestamp<WithId<MentoringSheetImageWithUploadUrlAndDownloadUrl>>
  >;
};

export const putMentoringSheetImage = async ({
  client_id,
  mentoring_sheet_id,
  image_id,
  token,
  ...data
}: {
  client_id: string;
  mentoring_sheet_id: string;
  image_id: string;
  token: string;
} & MentoringSheetImageBase) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}/images/${image_id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    }
  );

  if (!response.ok) {
    const text = await response.text();
    throw new Error(text || 'エラーが発生しました');
  }

  return response.json() as Promise<
    WithTimestamp<WithId<MentoringSheetImageWithUploadUrlAndDownloadUrl>>
  >;
};

export const patchMentoringSheetImage = async ({
  client_id,
  mentoring_sheet_id,
  image_id,
  file_name,
  sha256_hash,
  filesize_bytes,
  mime_type,
  status,
  reference_status,
  token,
}: {
  client_id: string;
  mentoring_sheet_id: string;
  image_id: string;
  token: string;
} & Partial<{
  file_name: string;
  sha256_hash: string;
  filesize_bytes: number;
  mime_type: string;
  status: 'pending' | 'uploaded';
  reference_status: 'referenced' | 'unreferenced';
}>) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}/images/${image_id}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      // stringify removes all undefined fields
      body: JSON.stringify({
        file_name,
        sha256_hash,
        filesize_bytes,
        status,
        reference_status,
        mime_type,
      }),
    }
  );

  if (!response.ok) {
    const text = await response.text();
    throw new Error(text || 'エラーが発生しました');
  }

  return response.json() as Promise<
    WithTimestamp<WithId<MentoringSheetImageBase>>
  >;
};

export const deleteMentoringSheetImage = async ({
  client_id,
  mentoring_sheet_id,
  image_id,
  token,
}: Partial<{
  client_id: string;
  mentoring_sheet_id: string;
  image_id: string;
  token: string;
}>) => {
  const response = await fetch(
    `${API_URL}/clients/${client_id}/mentoring-sheets/${mentoring_sheet_id}/images/${image_id}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    const text = await response.text();
    throw new Error(text || 'エラーが発生しました');
  }
};
