import { Box, Button, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  AddMentoringSheetDialog,
  AlignCenterGrid,
  DeleteMentoringSheetDialog,
  Loading,
  MentoringSheetForm,
  SelectSheet,
} from 'components';
import { useAppContext } from 'contexts';
import {
  createMentoringSheetAsync,
  fetchMentoringSheetFileAsync,
  fetchMentoringSheetFilesAsync,
  fetchMentoringSheetImagesAsync,
  mentoringSheetSlice,
  removeMentoringSheetAsync,
  removeMentoringSheetFileAsync,
  replaceMentoringSheetAsync,
  selectClientError,
  selectClientStatus,
  selectMentoringSheetError,
  selectMentoringSheetFiles,
  selectMentoringSheetImageError,
  selectMentoringSheets,
  selectMentoringSheetsStatus,
  selectMentoringSheetStatus,
  uploadMentoringSheetFileAsync,
  uploadMentoringSheetImageAsync,
} from 'features';
import { useToggle } from 'hooks';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { NewAPIMentoringSheetWithServerValues } from 'types';

export const AdminMentoringPage = () => {
  const { clientId } = useParams() as { clientId: string };
  const dispatch = useAppDispatch();
  const clientStatus = useAppSelector(selectClientStatus);
  const clientError = useAppSelector(selectClientError);
  const mentoringSheetsStatus = useAppSelector(selectMentoringSheetsStatus);
  const mentoringSheets = useAppSelector(selectMentoringSheets);
  const mentoringSheetStatus = useAppSelector(selectMentoringSheetStatus);
  const mentoringSheetError = useAppSelector(selectMentoringSheetError);
  const sheets = useAppSelector(selectMentoringSheets);
  const { draftMentoringSheet, setDraftMentoringSheet } = useAppContext();
  const mentoringSheetImageError = useAppSelector(
    selectMentoringSheetImageError
  );
  const [openAddMentoringSheetDialog, toggleOpenAddMentoringSheetDialog] =
    useToggle(false);
  const [openDeleteMentoringSheetDialog, toggleOpenDeleteMentoringSheetDialog] =
    useToggle(false);
  const [newSheetMeetingDate, setNewSheetMeetingDate] = useState<Date>(() => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    return today;
  });

  const mentoringSheetFiles = useAppSelector(selectMentoringSheetFiles);
  const uploadMentoringSheetFile = async (file: File) => {
    if (!draftMentoringSheet) {
      return;
    }
    dispatch(
      uploadMentoringSheetFileAsync({
        client_id: clientId,
        mentoring_sheet_id: draftMentoringSheet.id,
        file,
      })
    );
  };

  const uploadMentoringSheetImage = async (image: File) => {
    if (!draftMentoringSheet) {
      throw new Error('No draft mentoring sheet available');
    }
    const result = await dispatch(
      uploadMentoringSheetImageAsync({
        client_id: clientId,
        mentoring_sheet_id: draftMentoringSheet.id,
        image,
      })
    ).unwrap();
    return result;
  };

  const getMentoringSheetDownloadLink = async (file_id: string) => {
    const data = await dispatch(
      fetchMentoringSheetFileAsync({
        client_id: clientId,
        mentoring_sheet_id: draftMentoringSheet?.id ?? '',
        file_id,
      })
    ).unwrap();
    return data.download_url;
  };

  const removeMentoringSheetFile = async (file_id: string) => {
    if (!draftMentoringSheet) {
      return;
    }
    dispatch(
      removeMentoringSheetFileAsync({
        client_id: clientId,
        mentoring_sheet_id: draftMentoringSheet.id,
        file_id,
      })
    );
    return;
  };

  if (clientStatus === 'pending') {
    return <Loading />;
  }
  if (clientStatus === 'rejected') {
    return <div>{clientError}</div>;
  }
  if (mentoringSheetsStatus === 'succeeded' && mentoringSheets.length === 0) {
    return (
      <AlignCenterGrid>
        <Box display="flex" sx={{ px: 2 }}>
          <Typography>シートがありません</Typography>
          <OpenAddNewSheetDialogButton
            onClick={() => {
              toggleOpenAddMentoringSheetDialog();
            }}
          />
        </Box>
        <AddMentoringSheetDialog
          open={openAddMentoringSheetDialog}
          meetingDate={newSheetMeetingDate}
          setMeetingDate={d => setNewSheetMeetingDate(d)}
          create={({ meetingDate }) =>
            dispatch(
              createMentoringSheetAsync({
                client_id: clientId,
                data: {
                  meetingDate,
                  netWorthGoal: 0,
                  financialGoal: '',
                  todo: '',
                },
              })
            )
          }
          onClose={toggleOpenAddMentoringSheetDialog}
        />
      </AlignCenterGrid>
    );
  }
  if (!draftMentoringSheet) {
    return <Loading />;
  }
  return (
    <AlignCenterGrid>
      <Box display="flex" sx={{ px: 2 }}>
        <SelectSheet
          sheets={sheets}
          sheet={draftMentoringSheet}
          selectSheet={(sheet: NewAPIMentoringSheetWithServerValues) => {
            dispatch(mentoringSheetSlice.actions.selectMentoringSheet(sheet));
            dispatch(
              fetchMentoringSheetFilesAsync({
                client_id: clientId,
                mentoring_sheet_id: sheet.id,
              })
            );
            dispatch(
              fetchMentoringSheetImagesAsync({
                client_id: clientId,
                mentoring_sheet_id: sheet.id,
              })
            );
          }}
        />
        <OpenAddNewSheetDialogButton
          onClick={() => {
            toggleOpenAddMentoringSheetDialog();
          }}
        />
        <PublishButton
          isDraft={draftMentoringSheet.isDraft}
          onClick={() =>
            dispatch(
              replaceMentoringSheetAsync({
                client_id: clientId,
                id: draftMentoringSheet.id,
                data: {
                  ...draftMentoringSheet,
                  isDraft: !draftMentoringSheet.isDraft,
                },
              })
            )
          }
        />
      </Box>
      <MentoringSheetForm
        draftSheet={draftMentoringSheet}
        setDraftSheet={setDraftMentoringSheet}
        save={() => {
          const { id, ...data } = draftMentoringSheet;
          dispatch(
            replaceMentoringSheetAsync({
              id,
              client_id: clientId,
              data,
            })
          );
        }}
        mentoringSheetFiles={mentoringSheetFiles}
        getMentoringSheetDownloadLink={getMentoringSheetDownloadLink}
        uploadMentoringSheetFile={uploadMentoringSheetFile}
        uploadMentoringSheetImage={uploadMentoringSheetImage}
        removeMentoringSheetFile={removeMentoringSheetFile}
        status={mentoringSheetStatus}
        error={mentoringSheetError}
        imageError={mentoringSheetImageError}
      />
      <hr style={{ marginTop: 24 }} />
      <OpenDeleteSheetDialogButton
        onClick={toggleOpenDeleteMentoringSheetDialog}
      />
      <AddMentoringSheetDialog
        open={openAddMentoringSheetDialog}
        meetingDate={newSheetMeetingDate}
        setMeetingDate={d => setNewSheetMeetingDate(d)}
        create={({ meetingDate }) =>
          dispatch(
            createMentoringSheetAsync({
              client_id: clientId,
              data: {
                meetingDate,
                netWorthGoal: draftMentoringSheet.netWorthGoal,
                financialGoal: draftMentoringSheet.financialGoal,
                todo: draftMentoringSheet.nextActions,
              },
            })
          )
        }
        onClose={toggleOpenAddMentoringSheetDialog}
      />
      <DeleteMentoringSheetDialog
        open={openDeleteMentoringSheetDialog}
        deleteSheet={() => {
          if (draftMentoringSheet) {
            dispatch(
              removeMentoringSheetAsync({
                client_id: clientId,
                id: draftMentoringSheet.id,
              })
            );
          }
        }}
        onClose={toggleOpenDeleteMentoringSheetDialog}
      />
    </AlignCenterGrid>
  );
};

const PublishButton = ({
  isDraft,
  onClick,
}: {
  isDraft: boolean;
  onClick: () => void;
}) => (
  <Button
    color="secondary"
    variant="contained"
    size="small"
    sx={{ mx: 1 }}
    onClick={onClick}
  >
    {isDraft ? 'お客様に反映する' : '下書きに戻す'}
  </Button>
);
const OpenAddNewSheetDialogButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    variant="contained"
    color="secondary"
    size="small"
    sx={{ mx: 1, minWidth: 24 }}
    onClick={onClick}
  >
    新しいG-POP&reg;シートを作成
  </Button>
);

const OpenDeleteSheetDialogButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    variant="contained"
    color="warning"
    size="large"
    sx={{ mx: 2, minWidth: 24, mb: 12 }}
    onClick={onClick}
  >
    シートを削除
  </Button>
);
