import React from 'react';
import ExampleTheme from './themes/Theme';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
// import TreeViewPlugin from './plugins/TreeViewPlugin'; // For debugging
import ToolbarPlugin from './plugins/ToolbarPlugin';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import ImagesPlugin from './plugins/ImagesPlugin';
import './richtext_styles.css';
import { ImageNode } from './nodes/ImageNode';
import {
  MentoringSheetImageWithUploadUrlAndDownloadUrl,
  WithId,
  WithTimestamp,
} from 'types';
import DragDropPastePlugin from './plugins/DragDropPastePlugin';

function Placeholder() {
  return <div className="editor-placeholder"></div>;
}

export default function RichTextEditor({
  mtgMemoLexical,
  onChange,
  uploadImage,
}: {
  mtgMemoLexical: string;
  onChange: (editorStateJSON: string) => void;
  uploadImage: (
    image: File
  ) => Promise<
    WithTimestamp<WithId<MentoringSheetImageWithUploadUrlAndDownloadUrl>>
  >;
}) {
  const initConfig = {
    namespace: 'MyEditor',
    theme: ExampleTheme,
    editorState: mtgMemoLexical,
    onError(error: Error) {
      throw error;
    },
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      ImageNode,
    ],
  };

  return (
    <LexicalComposer initialConfig={initConfig}>
      <div className="editor-container">
        <ToolbarPlugin uploadImage={uploadImage} />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <DragDropPastePlugin uploadImage={uploadImage} />
          <ImagesPlugin />
          <HistoryPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <OnChangePlugin
            onChange={editorState => {
              const editorStateJSON = editorState.toJSON();
              onChange(JSON.stringify(editorStateJSON));
            }}
          />
        </div>
        {/* <TreeViewPlugin /> For debugging */}
      </div>
    </LexicalComposer>
  );
}
