import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  deleteMentoringSheetImage,
  getMentoringSheetImageById,
  getMentoringSheetImages,
  postMentoringSheetImage,
  putMentoringSheetImage,
  uploadFileWithXHR,
} from 'api';
import { RootState } from 'app/store';
import { createAppAsyncThunk } from 'app/withTypes';
import { getToken } from 'api';
import {
  MentoringSheetImageWithUploadUrlAndDownloadUrl,
  MentoringSheetImageWithTransferData,
  WithId,
  WithTimestamp,
} from 'types';
import { calculateSHA256Hash } from 'utils';

type MentoringSheetImageState = {
  status: 'idle' | 'pending' | 'succeeded' | 'rejected';
  images: WithTimestamp<WithId<MentoringSheetImageWithTransferData>>[];
  error: string | null;
};

const initialState: MentoringSheetImageState = {
  status: 'idle',
  images: [],
  error: null,
};

export const fetchMentoringSheetImagesAsync = createAppAsyncThunk(
  'client/fetchMentoringSheetImages',
  async ({
    client_id,
    mentoring_sheet_id,
  }: {
    client_id: string;
    mentoring_sheet_id: string;
  }) => {
    const token = await getToken();
    const response = await getMentoringSheetImages({
      client_id,
      mentoring_sheet_id,
      token,
    });
    return response;
  }
);

export const fetchMentoringSheetImageAsync = createAppAsyncThunk(
  'client/fetchMentoringSheetImage',
  async ({
    client_id,
    mentoring_sheet_id,
    image_id,
  }: {
    client_id: string;
    mentoring_sheet_id: string;
    image_id: string;
  }) => {
    const token = await getToken();
    const sheetImage = await getMentoringSheetImageById({
      client_id,
      mentoring_sheet_id,
      image_id,
      token,
    });
    return sheetImage;
  }
);

export const removeMentoringSheetImageAsync = createAppAsyncThunk(
  'client/deleteMentoringSheetImage',
  async ({
    client_id,
    mentoring_sheet_id,
    image_id,
  }: {
    client_id: string;
    mentoring_sheet_id: string;
    image_id: string;
  }) => {
    const token = await getToken();
    await deleteMentoringSheetImage({
      client_id,
      mentoring_sheet_id,
      image_id,
      token,
    });
    return;
  }
);

export const uploadMentoringSheetImageAsync = createAppAsyncThunk(
  'client/uploadMentoringSheetImage',
  async (
    {
      client_id,
      mentoring_sheet_id,
      image,
    }: {
      client_id: string;
      mentoring_sheet_id: string;
      image: File;
    },
    { dispatch, rejectWithValue }
  ) => {
    const token = await getToken();
    const arrayBuffer = await image.arrayBuffer();
    const sha256_hash = await calculateSHA256Hash(arrayBuffer);
    const postData = await postMentoringSheetImage({
      client_id,
      mentoring_sheet_id,
      file_name: image.name,
      sha256_hash,
      filesize_bytes: image.size,
      mime_type: image.type,
      status: 'pending',
      reference_status: 'unreferenced',
      uploaded_at: null,
      token,
    });
    // if `POST` throws an error, it will be handled in `extraReducers's .rejected`
    dispatch(mentoringSheetImageSlice.actions.startToUpload(postData));
    try {
      await uploadFileWithXHR({
        file: image,
        url: postData.upload_url,
        onprogress: (e: ProgressEvent) => {
          dispatch(
            mentoringSheetImageSlice.actions.updateProgress({
              image_id: postData.id,
              progress: e.loaded / e.total,
            })
          );
        },
      });
      const data = await putMentoringSheetImage({
        client_id: client_id,
        mentoring_sheet_id: mentoring_sheet_id,
        image_id: postData.id,
        file_name: image.name,
        sha256_hash,
        filesize_bytes: image.size,
        mime_type: image.type,
        status: 'uploaded',
        reference_status: 'unreferenced',
        uploaded_at: new Date().toISOString(),
        token,
      });
      return data;
    } catch (err) {
      dispatch(
        mentoringSheetImageSlice.actions.updateError({
          image_id: postData.id,
          error: err instanceof Error ? err.message : 'エラーが発生しました',
        })
      );
      await deleteMentoringSheetImage({
        client_id,
        mentoring_sheet_id,
        image_id: postData.id,
        token,
      });
      throw err;
    }
  }
);

export const mentoringSheetImageSlice = createSlice({
  name: 'mentoringSheetImage',
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
    startToUpload(
      state,
      action: PayloadAction<
        WithTimestamp<WithId<MentoringSheetImageWithUploadUrlAndDownloadUrl>>
      >
    ) {
      const ix = state.images.findIndex(
        image => image.id === action.payload.id
      );
      const newData = {
        ...action.payload,
        upload_progress: 0,
        error: null,
      } as const;

      if (ix !== -1) {
        state.images[ix] = {
          ...state.images[ix],
          ...newData,
        };
      } else {
        state.images = [...state.images, newData];
      }
    },
    updateProgress(
      state,
      action: PayloadAction<{ image_id: string; progress: number }>
    ) {
      state.images = state.images.map(image => {
        if (image.id === action.payload.image_id) {
          return {
            ...image,
            upload_progress: action.payload.progress,
          };
        }
        return image;
      });
    },
    updateError(
      state,
      action: PayloadAction<{ image_id: string; error: string }>
    ) {
      const ix = state.images.findIndex(
        image => image.id === action.payload.image_id
      );
      if (ix === -1) {
        return;
      }

      state.images[ix] = {
        ...state.images[ix],
        error: action.payload.error,
      };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMentoringSheetImagesAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(fetchMentoringSheetImagesAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.images = action.payload.map(f => ({
          ...f,
          upload_progress: null,
          error: null,
        }));
      })
      .addCase(fetchMentoringSheetImagesAsync.rejected, (state, action) => {
        state.status = 'rejected';
        state.error = action.error.message ?? 'エラーが発生しました';
      })
      .addCase(uploadMentoringSheetImageAsync.pending, (state, action) => {
        // handled in thunk
      })
      .addCase(uploadMentoringSheetImageAsync.fulfilled, (state, action) => {
        const ix = state.images.findIndex(
          image => image.id === action.payload.id
        );
        state.images = [
          ...state.images.slice(0, ix),
          {
            ...action.payload,
            upload_progress: null,
            error: null,
          },
          ...state.images.slice(ix + 1),
        ];
      })
      .addCase(uploadMentoringSheetImageAsync.rejected, (state, action) => {
        state.error = 'エラーが発生しました';
      })
      .addCase(removeMentoringSheetImageAsync.pending, (state, action) => {
        state.status = 'pending';
      })
      .addCase(removeMentoringSheetImageAsync.fulfilled, (state, action) => {
        const ix = state.images.findIndex(
          image => image.id === action.meta.arg.image_id
        );
        state.images = [
          ...state.images.slice(0, ix),
          ...state.images.slice(ix + 1),
        ];
      })
      .addCase(removeMentoringSheetImageAsync.rejected, (state, action) => {
        const ix = state.images.findIndex(
          image => image.id === action.meta.arg.image_id
        );
        state.images[ix].error = action.error.message ?? 'エラーが発生しました';
      });
  },
});

export const selectMentoringSheetImageStatus = (state: RootState) =>
  state.mentoringSheetImage.status;

export const selectMentoringSheetImageError = (state: RootState) =>
  state.mentoringSheetImage.error;

export const selectMentoringSheetImages = (state: RootState) =>
  state.mentoringSheetImage.images;
